import React from "react"
import {Link} from "gatsby"

const Footer = () => {

  return (
    <footer className="w-full absolute bottom-0 py-2 px-4 text-sm">
      <div>
        © {new Date().getFullYear()} Jendrik Sigwart, Photos courtesy of NDR | <Link to="/contact">Imprint</Link> | <Link to="/privacy">Privacy Policy</Link>
      </div>
    </footer>
  );
}

Footer.propTypes = {
}

Footer.defaultProps = {
}

export default Footer
