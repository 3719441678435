import React from "react";
import PropTypes from "prop-types"
import {Link} from "gatsby";

const CookieConsent = (props) => {
  return (
    <div className={`fixed bottom-0 w-full bg-black bg-opacity-60 backdrop-filter backdrop-blur-sm
                    px-6 pt-2 pb-4 flex flex-col sm:flex-row justify-between
                    transition-transform ${props.hidden ? 'transform translate-y-full' : ''}`}>
      <div>
        <h1 className="text-3xl my-2">I want your cookies 🍪</h1>
        <p className="text-base">
          Can we use cookies to measure <strong>anonymous</strong> usage data on this site?<br/>
          For more information, visit our <Link to="/privacy">Privacy Policy</Link>.
        </p>
      </div>
      <div className="flex flex-row-reverse sm:flex-col xl:flex-row justify-end items-end">
        <button className="ml-2 sm:ml-0 xl:mr-2 sm:mb-2 xl:mb-0 w-72 bg-white text-black text-base py-2 font-normal active:bg-opacity-70"
                disabled={props.hidden}
                onClick={props.onAccept}>
          Sure, it's okay.
        </button>
        <button className="w-72 text-base py-2 border-opacity-70 text-opacity-70 active:bg-white active:bg-opacity-10 active:text-white active:text-opacity-70"
                disabled={props.hidden}
                onClick={props.onDecline}>
          No, thanks
        </button>
      </div>
    </div>
  )
}

CookieConsent.propTypes = {
  hidden: PropTypes.bool.isRequired,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func
}

CookieConsent.defaultProps = {
  onAccept: () => console.log('Cookies accepted!'),
  onDecline: () => console.log('Cookies declined!')
}

export default CookieConsent
