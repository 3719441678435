/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, {useEffect, useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";
import CookieConsent from "./cookie-consent";
import {useCookies} from "react-cookie";

const Layout = ({ children, isHomepage, isBlurred, title }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const JENDRIKS_WORLD_ANALYTICS_CONSENT = 'jendriksworld_analytics_consent';
  const [cookies, setCookie,] = useCookies();
  const analyticsConsent = cookies[JENDRIKS_WORLD_ANALYTICS_CONSENT];

  const [cookieBannerHidden, setCookieBannerHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setCookieBannerHidden(analyticsConsent !== undefined);
    }, 500);
  }, [setCookieBannerHidden, analyticsConsent]);

  const handleAccept = () => {
    // store cookie consent (accept)
    setCookieBannerHidden(true);
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie(JENDRIKS_WORLD_ANALYTICS_CONSENT, true, {
      expires: oneYearFromNow
    });

    // enable Google Analytics and send page_view event
    window[`ga-disable-${process.env.GA_TRACKING_ID}`] = false;
    window.gtag('event', 'page_view', {
      page_title: title + ' | ' + data.site.siteMetadata.title,
      page_location: window.location.href,
      page_path: window.location.pathname,
      send_to: process.env.GA_TRACKING_ID
    })
  }

  const handleDecline = () => {
    // store cookie consent (decline)
    setCookieBannerHidden(true);
    const oneYearFromNow = new Date();
    oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
    setCookie(JENDRIKS_WORLD_ANALYTICS_CONSENT, false, {
      expires: oneYearFromNow
    });

    // disable Google Analytics (should already be disabled, just making sure :P)
    window[`ga-disable-${process.env.GA_TRACKING_ID}`] = true;
  }

  return (
    <>
      <SEO title={title}/>
      <div className="min-h-screen relative">
        { !isHomepage && <Header siteTitle={data.site.siteMetadata.title}/>}
        <main>
          <div className={`max-w-screen-sm mx-auto pt-12 pb-40 px-8 ${isBlurred ? 'backdrop-filter backdrop-blur-sm' : ''}`}>
            {children}
          </div>
        </main>
        <Footer/>
      </div>
      <CookieConsent onAccept={handleAccept} onDecline={handleDecline} hidden={cookieBannerHidden}/>
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isHomepage: PropTypes.bool,
}

Layout.propDefaults = {
  isHomepage: false
}

export default Layout
